import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from './local-storage.service';
import { JwtTokenService } from './jwt-token.service';
import { LogoutService } from './logout.service';
import { environment } from 'src/environments/environment';
import { AutenticacaoRequest } from './../models/autenticacao-request.model';
import { AutenticacaoResponse } from './../models/autenticacao-response.model';
import { AuthSysResponse } from './../models/auth-sys-response.model';
import { UserModel } from '../models/user-model';
import { LoginModel } from '../models/login-model';
import { LoginSocialModel } from '../models/login-social-model';

// TODO: Criei um atributo para a uri de autenticação, porque não
// tem distinção de ambiente no momento.

const url = environment.ConnectionConfig.hostAPI;
const pathAutenticacao = 'auth/oauth/v2/token-sem-cors';
const pathBuscarPermissoes = 'lista-restritiva/auth/';

@Injectable()

export class LoginService {

  constructor(
    protected http: HttpClient,
    private localStorageService: LocalStorageService,
    private jwtTokenService: JwtTokenService,
    private logoutService: LogoutService,
    private _http: HttpClient) { }

    login(login){
      return this._http.post<LoginModel[]>(`${url}api/Login`,login);
        
    }

    loginSocial(loginSocial){
      return this._http.post<LoginSocialModel[]>(`${url}api/Login/Social`,loginSocial);
        
    }    
}