import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http';
import { ClienteModel } from '../models/cliente-models';
import {environment} from '../../environments/environment'
const url = environment.ConnectionConfig.hostAPI;
@Injectable({
  providedIn: 'root'
})
export class ClienteService {


    
    constructor(private _http: HttpClient) {

    }  

    getAll(){
      return this._http.get<ClienteModel[]>(`${url}api/Cliente`);    
    }

    getById(id){
      return this._http.get<ClienteModel>(`${url}api/Cliente/${id}` );
    }

    getByEmpresaId(idEmpresa){
      return this._http.get<ClienteModel[]>(`${url}api/Cliente/Empresa/${idEmpresa}` );
    }    

    getByEmpresaIdAndNome(idEmpresa, nome){
      return this._http.get<ClienteModel>(`${url}api/Cliente/Empresa/${idEmpresa}/Nome/${nome}` );
    }        

    getByEmpresaIdAndTelefone(idEmpresa, telefone){
      return this._http.get<ClienteModel>(`${url}api/Cliente/Empresa/${idEmpresa}/Telefone/${telefone}` );
    }    

    getByEmpresaIdAndEmail(idEmpresa, email){
      return this._http.get<ClienteModel>(`${url}api/Cliente/Empresa/${idEmpresa}/Email/${email}`);
    }    

    delete(id) {
      return this._http.delete(`${url}api/Cliente/${id}`);
    }    

    post(cliente: ClienteModel) {
      return this._http.post(`${url}api/Cliente/`, cliente);
    }

    put(cliente: ClienteModel) {
      return this._http.put(`${url}api/Cliente/`, cliente);
    }    

    putIncrementarVisita(idCliente, cliente) {
      return this._http.put(`${url}api/Cliente/IncrementarVisita/${idCliente}`,cliente);
    }       
    putIncrementarAusencia(idCliente, cliente) {
      return this._http.put(`${url}api/Cliente/IncrementarAusencia/${idCliente}`,cliente);
    }       
    putIncrementarCancelamento(idCliente, cliente) {
      return this._http.put(`${url}api/Cliente/IncrementarCancelamento/${idCliente}`,cliente);
    }               

}
