import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router) { }

  logout(): void {
    this.localStorageService.remove('token');
    this.localStorageService.remove('auth');
    this.localStorageService.remove('dataAuth');
    this.localStorageService.remove('atendimento');
    this.localStorageService.remove('usuario');
    this.localStorageService.remove('functionalities');
    this.router.navigate(['login']);
  }
}
