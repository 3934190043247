import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClienteModel } from '../models/cliente-models';
import { ReservaModel } from '../models/reserva-model';
import { EmpresaModel } from '../models/empresa-model';
import { environment } from 'src/environments/environment';
import { LogModel } from '../models/log-model';
const url = environment.ConnectionConfig.hostAPI;
@Injectable()

export class ReservaService {

  constructor(private _http: HttpClient) {
 
  }
  
  getAll() {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva`);
  }

  getById(id) {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/${id}`);
  }

  getByEmpresaId(idEmpresa) {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}`);
  }

  getByEmpresaIdAndNome(idEmpresa,nome) {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Nome/${nome}`);
  }

  getByEmpresaAndCliente(idEmpresa, idCliente) {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Cliente/${idCliente}`);
  }

  getByEmpresaAndEmail(idEmpresa,email) {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Email/${email}`);
  }

  getByEmpresaAndData(idEmpresa, data) {
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Dia/${data}`);
  }

  getByEmpresaAndPeriodo(idEmpresa, inicio, termino){
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Inicio/${inicio}/Termino/${termino}`);
  }

  getByEmpresaAndPeriodoAndNome(idEmpresa, inicio, termino, nome){
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Inicio/${inicio}/Termino/${termino}/Nome/${nome}`);
  }  

  getByEmpresaAndOrigem(idEmpresa, idOrigem){
    return this._http.get<ReservaModel>(`${url}api/Reserva/Empresa/${idEmpresa}/Origem/${idOrigem}`);
  }  

  getByEmpresaAndSituacao(idEmpresa, idSituacao){
    return this._http.get<ReservaModel>(`${url}api/Reserva/Empresa/${idEmpresa}/Situacao/${idSituacao}`);
  }  

  getByEmpresaAndTelefone(idEmpresa, numero){
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Telefone/${numero}`);
  }  

  getByHorarioDisponivel(idEmpresa, idObjeto, data){
    return this._http.get<ReservaModel>(`${url}api/Reserva/HorariosDisponiveis/Empresa/${idEmpresa}/Objeto/${idObjeto}/Dia/${data}`);
  }    

  delete(id){
    return this._http.delete<ReservaModel[]>(`${url}api/Reserva/${id}`);
  }  

  put(reserva: ReservaModel){
    return this._http.put<ReservaModel[]>(`${url}api/Reserva/`, reserva);
  }     

  post(reserva: ReservaModel){
    return this._http.post<ReservaModel[]>(`${url}api/Reserva/`, reserva);

  }
  
  cancelarReserva(id, observacao){
    let cancelamento = {id:id,obs:observacao};
    return this._http.put<ReservaModel[]>(`${url}api/Reserva/Cancelar`, cancelamento);
  }
  informarChegada(id, observacao){
    let chegada = {id:id,obs:observacao};
    return this._http.put<ReservaModel[]>(`${url}api/Reserva/InformarChegada`, chegada);
  }
  informarNaoComparecimento(id, observacao){
    let ausencia = {id:id,obs:observacao};
    return this._http.put<ReservaModel[]>(`${url}api/Reserva/InformarNaoComparecimento`, ausencia);
  }
  finalizarReserva(id, observacao){
    let finalizar = {id:id,obs:observacao};
    return this._http.put<ReservaModel[]>(`${url}api/Reserva/Finalizar`, finalizar);
  }  
  getLog(idEmpresa, idReserva){
    return this._http.get<LogModel[]>(`${url}api/Reserva/Log/Empresa/${idEmpresa}/Reserva/${idReserva}`);
  }

  getFiltro(idEmpresa, inicio, termino, nome){
    return this._http.get<ReservaModel[]>(`${url}api/Reserva/Empresa/${idEmpresa}/Inicio/${inicio}/Termino/${termino}/Nome/${nome}`);
  }  
  
}
