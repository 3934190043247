import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http';
import { UsuarioModel} from '../models/usuario-model';
import {environment} from '../../environments/environment'
const url = environment.ConnectionConfig.hostAPI;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
   
  constructor(private _http: HttpClient) {

  } 

  getAll(){
    return this._http.get<UsuarioModel[]>(`${url}api/Usuario`);
      
  }

  getById(id){
    return this._http.get<UsuarioModel>(`${url}api/Usuario/${id}` );
  }

  getByEmpresa(id) {
    return this._http.get(`${url}api/Usuario/Empresa/${id}`);
  }    

  delete(id) {
    return this._http.delete(`${url}api/Usuario/${id}`);
  }      

  post(usuario: UsuarioModel) {
    return this._http.post(`${url}api/Usuario`, usuario);
  }

  put(usuario: UsuarioModel) {

    return this._http.put(`${url}api/Usuario`, usuario);
  }
  
  getByEmpresaAndEmail(id,email) {
      return this._http.get(`${url}api/Usuario/Empresa/${id}/email/${email}`);
  }    

  getByEmpresaAndUid(id,uid) {
    return this._http.get(`${url}api/Usuario/Empresa/${id}/uid/${uid}`);
  }     
}