import { Injectable } from '@angular/core';
import { ConfigGeralModel } from './../models/config-geral-model';
import { EmpresaModel } from './../models/empresa-model';
import { HttpClient, HttpHeaders, HttpHandler } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ConfigSemanaModel } from '../models/config-semana-model';
import { ConfigDiaAtipicoModel } from '../models/config-dia-atipico-model';

const url = environment.ConnectionConfig.hostAPI;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private _http: HttpClient) { }

  getAll(nome) {
    return this._http.get<ConfigGeralModel[]>(`${url}api/ConfiguracaoParametros/${nome}`);
  }  
  
  getByIdEmpresa(id) {
    return this._http.get<ConfigGeralModel[]>(`${url}api/ConfiguracaoParametros/empresa/${id}`);
  }

  getById(id) {
    return this._http.get<ConfigGeralModel[]>(`${url}api/ConfiguracaoParametros/${id}`);
  }

  post(csnu: ConfigGeralModel){
    return this._http.post<ConfigGeralModel>(`${url}api/ConfiguracaoParametros/`,csnu);
  }

  put(csnu: ConfigGeralModel){
    return this._http.put<ConfigGeralModel>(`${url}api/ConfiguracaoParametros/`,csnu);
  }

  delete(id){
    return this._http.delete<ConfigGeralModel>(`${url}api/ConfiguracaoParametros/${id}`);
  }

  putConfigSemana(configSemana: ConfigSemanaModel){
    return this._http.put<ConfigSemanaModel>(`${url}api/ConfiguracaoReserva/`,configSemana);
  }

  postConfigSemana(configSemana: ConfigSemanaModel){
    return this._http.post<ConfigSemanaModel>(`${url}api/ConfiguracaoReserva/`,configSemana);
  }

  deleteConfigSemana(id){
    return this._http.delete<ConfigSemanaModel>(`${url}api/ConfiguracaoReserva/${id}`);
  }
  getConfigSemana(idEmpresa,diaSemana){
    let objeto=1;
    return this._http.get(`${url}api/ConfiguracaoReserva/Empresa/${idEmpresa}/Objeto/${objeto}/DiaSemana/${diaSemana}`);
  }
  getDiaAtipicoByIdEmpresaAndObjeto(idEmpresa){
    let objeto=1;
    return this._http.get<ConfigDiaAtipicoModel[]>(`${url}api/ConfiguracaoDiaAtipico/Empresa/${idEmpresa}/Objeto/${objeto}`);    
  }
  deleteDiaAtipico(id){
    return this._http.delete<ConfigDiaAtipicoModel>(`${url}api/ConfiguracaoDiaAtipico/${id}`);    
  }
  postDiaAtipico(configDiaAtipico){
    return this._http.post<ConfigDiaAtipicoModel>(`${url}api/ConfiguracaoDiaAtipico/`,configDiaAtipico);    
  }

  putDiaAtipico(configDiaAtipico){
    return this._http.put<ConfigDiaAtipicoModel>(`${url}api/ConfiguracaoDiaAtipico/`,configDiaAtipico);    
  }  
}




